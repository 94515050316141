import React from 'react';
import TestimonialsEn from '../components/Testimonials/TestimonialsEn';
import Layout from '../components/layout'

// import TwoSlideHome from '../components/HomepageContent/TwoSlideHome'

const Newfonthome = ({ location }) => {

    return (
    <Layout location = {location}>
    <section className = "w-full p-0 pb-0 lg:p-5 flex flex-col place-items-center bg-indigo-100 relative overflow-x-hidden z-0">
        <div className = "flex flex-col justify-center items-center w-full md:w-5/6 xl:w-5/6 p-3 lg:p-4">
            <h2 className = "bannerSectionTitle my-2 xl:my-4 text-purple-100">All tools to <span className = "relative">create<img className = "absolute bottom-0 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /> </span> the <br />best work culture is here.</h2>
            <p className = "text-purple-100 text-center my-2 xl:my-6  text-lg xl:text-xl z-20">Improve employee engagement with our employee rewards, on-spot recognition, employee<br/> feedback and corporate wellness solutions designed for today's global workforce.</p>
            
            {/* <div className = "grid grid-cols-1 w-4/6 md:w-1/2 lg:w-4/12 xl:w-1/4 2xl:w-1/5 mt-12 mb-9">
                <button className = "vc-new-orange-btn lato">Request Demo</button>
            </div> */}
               
               {/* <button className = "vc-new-ghost-btn-b0 lato">Free Trial</button> */}
            
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 my-12 w-4/6 xl:w-1/2">
                <a href="/request-demo/" class="vc-new-orange-btn lato">Request Demo</a>
                <button class="vc-ghost-btn-white lato modal-open modal-toggle">Watch Video</button>
            </div>
            
            <div className = "hidden lg:block py-0 lg:py-16 lg:my-2"></div>
        </div>
        <img className = "hidden lg:block absolute bottom-0 right-6 z-10" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/VC_Hero_Right_imagesWeb.png" width = "237" height = "332" alt = "VC_Hero_Right_imagesWeb" />
        <picture className='absolute bottom-0 left-0 z-10'>
            <source media = "(min-width: 1024px)" srcset= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/VC_Hero_Left-imagesWeb-1.png"  alt="VC_Hero_Left-imagesWeb-1.png"/>
            <img src="" alt="" />
        </picture>
        {/* <img className = "hidden lg:block absolute bottom-0 left-0 z-10" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/VC_Hero_Left-imagesWeb-1.png" width = "351" height = "348" alt = "VC_Hero_Left-imagesWeb-1.png" /> */}
        <img className= "hidden lg:block absolute bottom-1/2 right-5 opacity-40 z-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1623747897/gatsbycms/uploads/2021/06/circle-white.png" height= "125" width= "125" />
        <div className = "w-full flex justify-end lg:hidden">
            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/VC_HP_MObile_Hero.png" width = "1269" height = "865" alt = "VC_HP_MObile_Hero"/>
        </div>
        {/* <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/circle-white.png" className = "absolute right-0 bottom-20" /> */}
    </section>
    <section className = "w-full flex flex-col place-items-center bg-1">
        <div className = "newSectionContainer grid grid-cols-1 gap-5 justify-center items-center">
            <div className = "flex flex-col itesm-center lg:items-start justify-center lg:py-20">
                <h2 className = "subSectionTitle text-gray-250 text-center lg:text-left">HR Digital Transform <br/>through the <span className = "relative">3Ts<img className = "absolute bottom-0 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /></span></h2>
                {/* <p className = " text-lg xl:text-xl text-center lg:text-left my-10 text-gray-250">A single platform to engage with your team member and cultivate trust. Tackle challenges and build strengths so your team can do its best work.</p> */}
            </div>
            <div className = "relative flex justify-center items-center">
                <img src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/3Ts.png" width = "1269" height = "865" alt = "3Ts" />
            </div>
        </div>
    </section>

    {/* <section className = "w-full flex flex-col place-items-center">
        <div className = "w-full flex flex-col justify-center items-center py-2 px-0 lg:px-6">
            <div className ="c-logo-strip w-full lg:w-11/12 py-10 my-8 md:my-10 lg:my-16 2xl:my-20"></div>
            <a href = "#" className = "text-purple-500 font-bold flex flex justify-center text-xl items-center mb-6 ">See more clients <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg></a>
        </div>
    </section> */}

    <section class="px-6 pt-10 md:pt-10 w-full flex flex-col place-items-center">
        <h2 class="section-title text-center">Building Culture of Recognition at <span class="relative">600+ <img class="absolute bottom-0 right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png"/></span> Global Organizations.</h2>
        <div class="w-full flex flex-col justify-center items-center py-2 px-0 lg:px-6">
            <div class="c-logo-strip w-full lg:w-11/12 py-10 my-8 md:my-10 lg:my-16 2xl:my-20"></div>
            <a href = "#" className = "text-purple-500 font-bold flex flex justify-center text-xl items-center mb-6 ">See more clients <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg></a>
        </div>
    </section>

    <section className = "w-full flex justify-center items-center pt-2 lg:pt-10 relative overflow-x-hidden bg-2">
                <div className = "newSectionContainer flex flex-col justify-center items-center pt-10 lg:py-10 z-10">
                    <div className = "w-full flex flex-col justify-center items-center lg:items-start">
                        <div className = "flex justify-center items-center px-4 py-1 rounded-full border border-purple-200 bg-purple-100">
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40/vantagecms/uploads/2020/08/vantagerewards-icon.png" alt="Vantage Rewards" height ="15" width = "15" />
                            <h2 className = "text-purple-200 text-sm lato"><span className = "font-bold">&nbsp;&nbsp;VANTAGE </span>REWARDS</h2>
                        </div>
                        <h2 className = "text-gray-250 subSectionTitle mt-7 font-bold text-center w-full lg:w-4/6 2xl:w-9/12"><span className = "text-purple-500">Recognition platform</span> that speaks out your <span className = "relative">culture.<img className = "absolute bottom-0 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /></span> </h2>
                        <p className = "text-center lg:text-left w-full md:w-5/6 lg:w-1/2 my-3 text-gray-250 text-lg xl:text-xl mt-10 mb-8 lg:mb-20">Automate and simplify your employee rewards and recognition program with Vantage Rewards easy-to-use and customizable cloud-based solution.</p>
                    </div>
                    
                    
                    <div className = "rounded-t-lg flex flex-col place-items-center my-0 w-full px-0 pt-3 lg:pt-10 pb-10 lg:pb-16 lg:px-0 relative">
                        <img className = "w-full hidden lg:block" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Web-Rewards-Main-screen.png" loading = "lazy" width = "1265" height = "719" alt = "Web-Rewards-Main-Screen" />
                        <img className = "w-full block lg:hidden" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-Mobile-Vc-HP.png" loading = "lazy" width = "678" height = "687" alt = "rewards_Mobile_VC"/>
                        <div className = "w-5/6 md:w-1/2 lg:w-5/12 xl:w-3/12 2xl:w-1/5 mt-12"><button className = "vc-new-orange-btn lato">Explore Vantage Rewards</button></div>
                        <img className = "hidden lg:block absolute lg:right-40 xl:right-1/3 lg:-top-20 xl:-top-16 2xl:-top-8" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-27.png" width = "349" height = "152" alt = "Group-27"/>
                        <img className = "hidden lg:block absolute lg:-right-28 xl:-right-16 2xl:right-0 lg:bottom-16 xl:bottom-16 2xl:bottom-24" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-29.png" width = "257" height = "138" alt = "Group-29"/>
                    </div>
                    <div className = " grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-7 justify-center items-center p-5 lg:p-10 bg-indigo-100 rounded-lg shadow">
                            <div className = "w-full flex flex-col text-center lg:text-left xl:ml-6 2xl:ml-0 justify-center lg:items-start items-center">
                                <h2 className = "text-center lg:text-left text-3xl xl:text-3xl text-purple-100 my-5 tracking-wide">Reimagining employee recognition through the <span className = "text-purple-100 font-bold">AIR<span>e</span> Framework</span></h2>
                                <div className = "grid grid-cols-1 justify-center items-center lg:justify-start w-full md:w-4/6 lg:w-5/6 2xl:w-3/6 my-3">
                                    <button className = "vc-white-btn">Download Whitepaper</button>
                                </div>
                            </div>
                            <div class="w-full flex justify-center lg:justify-end items-center my-9 lg:my-0">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/AIRe-Illus-1.png" alt="Vantage Rewards" width = "550" height = "109"/>
                            </div>
                        </div>
                </div>

                <picture className='absolute right-0 top-40 2xl:top-80 z-0'>
                    <source media = "(min-width: 1024px)" srcset= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Stroke-1-Rewards-HP.png"  alt="stroke"/>
                    <img src="" alt="" />
                </picture>
               
                {/* <img className = "hidden lg:block absolute right-0 top-40 2xl:top-80 z-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Stroke-1-Rewards-HP.png" alt = "stroke"/> */}
        </section>
        <section className = "w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-0 lg:py-12 overflow-y-hidden bg-3">
            <div className = "newSectionContainer grid grid-cols-1 lg:grid-cols-2 gap-5 justify-center items-center z-10">
                    <div className = "w-full flex flex-col text-center lg:text-left justify-center lg:items-start items-center">
                        <div className = "flex justify-center items-center px-4 py-1 rounded-full" style = {{background: '#3b3b75'}}>
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/pulse-ico.png" alt="Vantage Pulse" height ="15" width = "15" />
                            <h2 className = "text-purple-100 text-sm lato"><span className = "font-bold">&nbsp;&nbsp;VANTAGE </span>PULSE</h2>
                        </div>
                        <h2 className = "text-purple-100 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-7 mb-1 font-bold text-center">Check real-time <span className = "relative">pulse<img className = "absolute -bottom-1 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /></span></h2>
                        <h2 className = "text-gray-100 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl mb-5 font-bold text-center">of your organization.</h2>
                        <p className = "my-8 text-lg xl:text-xl text-purple-100">Real-time employee feedback, actionable data, increased performance. Pulse lets you do all these and more with our people-first survey tool.</p>
                        <div className = "grid grid-cols-1 gap-3 justify-center items-center lg:justify-start w-5/6 md:w-1/2 cursor-pointer">
                            <button className = "vc-new-orange-btn lato">Explore Vantage Pulse</button>
                        </div>
                    </div>
                    <img loading = "lazy" className = "hidden lg:block absolute top-32 xl:top-24 2xl:top-16 right-32 xl:right-56 2xl:right-64" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-31.png" />
                    <div className = "w-full flex lg:hidden justify-center items-center mt-6">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Pulse-HP-Mobile.png" alt="Vantage Rewards" width="751" height="443" />
                    </div>
            </div>
            <div class="z-10 lg:flex justify-center lg:justify-end items-center my-9 lg:my-0 absolute lg:-right-40 xl:-right-28 2xl:-right-10 -bottom-10 hidden transform lg:scale-75 xl:scale-90 2xl:scale-100">
                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Pulse-Mainscreen.png" alt="Vantage Rewards" width="751" height="443" />
            </div>
            <picture className='absolute top-20 right-0 z-0'>
                    <source media = "(min-width: 1024px)" srcset= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/pulse-stroke-1.png" />
                    <img src="" alt="" />
            </picture>
            {/* <img className = "hidden lg:block absolute top-20 right-0 z-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/pulse-stroke-1.png" /> */}
        </section>
        <section className = "w-full flex place-content-center bg-white relative">
            <div className = "newSectionContainer grid grid-cols-1 gap-4 lg:gap-7 justify-center items-center my-5">
                <div className = "w-full flex flex-col text-center lg:text-left justify-center lg:items-start items-center"> 
                    <div className = "flex justify-center items-center px-4 py-1 rounded-full border border-purple-200 bg-purple-100">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40/vantagecms/uploads/2020/08/vantageperks-icon.png" alt="Vantage Perks" height ="15" width = "15" />
                        <h2 className = "text-purple-200 text-sm lato"><span className = "font-bold">&nbsp;&nbsp;VANTAGE </span>PERKS</h2>
                    </div>
                    <h2 className = "text-gray-250 subSectionTitle mt-7">Corporate <span className = "relative">offers <img className = "absolute bottom-0 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" alt = "stroke" /></span>that make</h2>
                    <h2 className = "text-gray-250 subSectionTitle mb-5">your team feel privileged.</h2>
                    <p className = "my-8 text-lg xl:text-xl text-gray-250 w-full lg:w-4/6">A comprehensive employee benefits program saves your employees money by providing financial wellness packages with unbeatable perks and corporate discounts.</p>
                </div>
                <div class="w-full flex justify-center items-center my-9 lg:my-0">
                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Web_Perks-Main-Screen.png" alt="Vantage Rewards" width="3251" height="1443" />
                </div>
                <div className = "w-full flex place-content-center"><div className = "w-5/6 md:w-1/2 lg:w-5/12 xl:w-3/12 2xl:w-1/5 mt-12"><button className = "vc-new-orange-btn lato">Explore Vantage Perks</button></div></div>
            </div>
            <img className = "hidden lg:block absolute bottom-0 xl:bottom-5 2xl:bottom-10 right-0 xl:right-24 2xl:right-48" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-33.png" alt = "Group-33"/>
        </section>
        {/* <section className = "w-full flex place-content-center bg-white relative" style = {{background : '#f1f1f1'}}>
                <div className = "newSectionContainer grid grid-cols-1 gap-4 lg:gap-7 justify-center items-center my-5">
                    <div className = "w-full flex flex-col text-center lg:text-left xl:ml-6 2xl:ml-0 justify-center lg:items-start items-center">
                        <div className = "flex justify-center items-center px-4 py-1 rounded-full border border-red" style = {{backgroundColor : "rgba(255, 0, 0, 0.1)"}}>
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40,h_40/gatsbycms/uploads/2021/06/vantagefit-logo.png" alt="Vantage Fit" height ="15" width = "15" />
                            <h2 className = "text-gray-500 text-sm lato"><span className = "font-bold">&nbsp;&nbsp;VANTAGE </span>FIT</h2>
                        </div>
                        <h2 className = "text-red text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl mt-7 mb-1 font-bold text-center">Wellness Platform<span className = "text-gray-500"> for a</span></h2>
                        <h2 className = "text-gray-500 text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl mb-5 font-bold text-center">healthy workforce.</h2>
                        <p className = "my-8 text-lg lg:text-xl text-gray-500">An AI Powered Corporate wellness solution to enhance employee wellbeing and productivity.</p>
                        <div className = "grid grid-cols-1 gap-3 justify-center items-center lg:justify-start w-5/6 lg:w-1/2">
                            <button className = "px-3 py-2 bg-red rounded text-gray-100" style = {{boxShadow : '1px 1px 15px rgba(0,0,0,0.3)'}}>Explore Vantage Fit</button>
                        </div>
                    </div>
                    <div class="w-full lg:flex justify-center lg:justify-end items-center my-9 lg:my-0 absolute lg:-right-40 2xl:-right-40 -bottom-10 hidden transform lg:scale-75 xl:scale-100 2xl:scale-100">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/01/Vfit.png" alt="Vantage Rewards" width="751" height="443" />
                    </div>
                    <div className = "w-full flex lg:hidden justify-center items-center ml-6 md:ml-16 lg:ml-0 my-9 transform scale-110">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/01/Vfit.png" alt="Vantage Rewards" width="751" height="443" />
                    </div>
                </div>
            </section> */}
        <section className = "w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-0 lg:py-32 2xl:py-40 overflow-y-hidden bg-4">
            <div className = "newSectionContainer grid grid-cols-1 lg:grid-cols-2 gap-5 justify-center items-center z-10">
                    <div className = "w-full flex flex-col text-center lg:text-left justify-center lg:items-start items-center">
                        <div className = "flex justify-center items-center px-4 py-1 rounded-full border border-red bg-5">
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40,h_40/gatsbycms/uploads/2021/06/vantagefit-logo.png" alt="Vantage Fit" height ="15" width = "15" />
                            <h2 className = "text-gray-500 text-sm lato"><span className = "font-bold">&nbsp;&nbsp;VANTAGE </span>FIT</h2>
                        </div>
                        <h2 className = "text-gray-250 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-7 mb-1 font-bold">Wellness Platform for a <br/><span className = "relative">healthy workforce.<img className = "absolute -bottom-4 right-0 2xl:right-6" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/vfit-stroke-1-1.png" /></span></h2>
                        <p className = "my-8 text-lg xl:text-xl text-gray-250">Real-time employee feedback, actionable data, increased performance. Pulse lets you do all these and more with our people-first survey tool.</p>
                        <div className = "grid grid-cols-1 gap-3 justify-center items-center lg:justify-start w-5/6 md:w-1/2 cursor-pointer">
                            <button className = "vfit-btn lato">Explore Vantage Pulse</button>
                        </div>
                    </div>
                    <img loading = "lazy" className = "hidden lg:block absolute top-4 xl:top-0 2xl:top-4 right-80 xl:right-56 2xl:right-64" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-40.png" width = "295" height = "153" alt = "Group-40" />
                    <div className = "w-full flex lg:hidden justify-center items-center mt-6">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Vfit-Main-Screen-mobile.png" alt="Vantage Rewards" width="751" height="443" />
                    </div>
            </div>
            <div class="z-10 lg:flex justify-center lg:justify-end items-center my-9 lg:my-0 absolute lg:-right-40 xl:-right-9 2xl:-right-10 bottom-0 hidden transform lg:scale-75 xl:scale-90 2xl:scale-100" >
                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Vfit-Main-Screen-1.png" alt="Vantage Fit" width="700" height="400" />
            </div>
            <img className = "hidden lg:block absolute bottom-36 xl:bottom-24 2xl:bottom-24 right-1/3 xl:right-1/3 2xl:right-96 mr-24 2xl:mr-64 xl:mr-32" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-39.png" width = "195" height = "120" />
        </section> 
        <section className = "w-full flex flex-col place-items-center z-0">
                <div className = "newSectionContainer flex flex-col justify-center items-center bg-white">
                    <h2 className = "text-gray-500 subSectionTitle mt-10 mb-5 font-bold text-center">We are <span className = "relative">recognized<img className = "absolute -bottom-2 lg:-bottom-4 right-0 xl:right-12" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /></span> globally</h2>
                    <div className = "w-full grid grid-cols-3 md:grid-cols-5 gap-6 justify-center items-center my-1 lg:mt-10 lg:mb-3 px-5 py-5">
                        <div className = "flex place-content-center"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/08/best-support.png" alt="G2 award4" width="110" height="125" /></div>
                        <div className = "flex place-content-center"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548690/gatsbycms/uploads/2021/12/crozdesk-employee-engagement-software-leader-badge.png" alt="Crozdesk award1" width="100" height="110" /></div>
                        <div className = "flex place-content-center"><a href="https://www.softwarereviews.com/" target="_blank" rel="nofollow noopener"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664738/gatsbycms/uploads/2021/08/Software-reviews-_resized.png" alt="Software Reviews award" width="110" height="100"/></a></div>
                        <div className = "flex place-content-center"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548691/gatsbycms/uploads/2021/08/crozdesk-4.png" alt="Crozdesk award4" width="110" height="110" /></div>
                        <div className = "flex place-content-center"><img className ="" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/08/leader-enterprise_resized.png" alt="G2 award1" width="110" height="125"></img></div>
                    </div>
                </div>
                <div class="newSectionContainer mx-auto px-6 pt-10 md:pt-0 pb-16">
                <div class="md:mt-10 grid grid-cols-1 sm:gap-3 md:gap-9 lg:grid-cols-3">
                    <div class="w-full mb-5 rounded-lg overflow-hidden shadow-lg transform transition duration-500 bg-gray-100 border-2 border-white ease-in-out">
                        <div class="p-5 md:p-8">
                            <div class="text-gray-500 text-center inline-flex items-center justify-center mt-2 mb-8">
                                <img loading="lazy" class="max-h-6" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/vantagecms/uploads/2020/06/yourstory_logo.png" alt="Your Story" width="118" height="24"/>
                            </div>
                            <h3 class="line-clamp-2 md:h-14 lato">We are changing the way HR manages Employee Engagement and Benefits programmes.</h3>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="https://yourstory.com/2020/06/startup-bharat-guwahati-vantage-circle-employee-engagement-coronavirus" target="_blank" rel="noopener noreferrer">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase lato">Read more</span>
                                    <svg class="w-6 h-6 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="w-full mb-5 rounded-lg overflow-hidden shadow-lg transform transition duration-500 bg-gray-100 border-2 border-white ease-in-out">
                        <div class="p-5 md:p-8">
                            <div class="text-gray-500 text-center inline-flex items-center justify-center mt-2 mb-8">
                                <img loading="lazy" class="max-h-6" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/uploads/2018/07/logo-forbes-black.png" alt="Forbes India" width="92" height="24"/>
                            </div>
                            <h3 class="line-clamp-2 md:h-14 lato">Nine startups with promise</h3>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="http://www.forbesindia.com/article/9th-anniversary-special/nine-startups-with-promise/50217/1" target="_blank" rel="noopener noreferrer">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase lato">Read more</span>
                                    <svg class="w-6 h-6 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="w-full mb-5 rounded-lg overflow-hidden shadow-lg transform transition duration-500 bg-gray-100 border-2 border-white ease-in-out">
                        <div class="p-5 md:p-8">
                            <div class="text-gray-500 text-center inline-flex items-center justify-center mt-2 mb-8">
                                <img loading="lazy" class="max-h-6" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/fe-logo.png" alt="Finacial Express" width="240" height = "200"/>
                            </div>
                            <h3 class="line-clamp-2 md:h-14 lato">Vantage Circle: Keeping employees happy</h3>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="https://www.financialexpress.com/industry/sme/vantage-circle-keeping-employees-happy/2252242/" target="_blank" rel="noopener noreferrer">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase lato">Read more</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                    <div className = "w-5/6 md:w-1/2 lg:w-2/6 grid grid-template-cols-1 lg:grid-cols-2 gap-5">
                        <a href="/in-the-press/" class="vc-ghost-btn lato">
                            See Awards
                        </a>
                        <a href="/in-the-press/" class="vc-ghost-btn lato">
                            See Press
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <div className= "testimonial-slider container text-center px-6 py-10 md:py-28">
            <TestimonialsEn />
        </div>
        <section className = "w-full flex place-content-center mb-10 lg:mt-9 lg:mb-20">
                <div className = "w-5/6 xl:w-5/6 2xl:w-4/6 flex flex-col place-items-center p-5 pb-0 rounded-xl md:rounded-2xl xl:rounded-4xl relative bg-indigo-100">
                {/* style = {{ backgroundImage: "linear-gradient(180deg, #3E3E6F 0%, #29294C 100%)", overflow : "hidden" }} */}
                    <h2 className = "font-bold text-purple-100 subSectionTitle mt-9 text-center">Grow with us.</h2>
                    <p className = "text-center w-5/6 lg:w-4/6 py-3 text-lg xl:text-xl text-purple-100">Join the bandwagon and help us deliver you a satisfying employee experience with our global employee engagement and wellness platform.</p>
                    <div className = "w-full pl-3 xl:pl-0 grid grid-cols-2 lg:grid-cols-4 justify-center items-center my-5">
                        <div className = "flex justify-start md:justify-center items-center border-r border-purple-100">
                            <div className = "flex flex-col items-start md:items-center xl:items-start justify-center">
                                <h2 className = "text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">70+</h2>
                                <p className = "text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Countries</p>
                            </div>
                        </div>
                        <div className = "flex pl-3 xl:pl-0 justify-start md:justify-center items-center lg:border-r border-purple-100">
                            <div className = "flex flex-col items-start md:items-center xl:items-start justify-center">
                                <h2 className = "text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">6K+</h2>
                                <p className = "text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Reward Options</p>
                            </div>
                        </div>
                        <div className = "flex justify-start md:justify-center items-center border-r border-purple-100">
                            <div className = "flex flex-col items-start md:items-center xl:items-start justify-center">
                                <h2 className = "text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">1.5M+</h2>
                                <p className = "text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Corporate Employees</p>
                            </div>
                        </div>
                        <div className = "flex pl-3 xl:pl-0 justify-start md:justify-center items-center">
                            <div className = "flex flex-col items-start md:items-center xl:items-start justify-center">
                                <h2 className = "text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">630+</h2>
                                <p className = "text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Companies</p>
                            </div>
                        </div>
                    </div>
                    <div className = "w-full flex place-content-center pt-12">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/GLobe-Homepage.png" loading = "lazy" />
                    </div>
                    <div className = "hidden lg:block absolute bottom-50 left-50 opacity-20 transform -translate-y-4 lg:-translate-y-1/4 xl:-translate-y-28 2xl:-translate-y-28 w-11/12 2xl:w-11/12 gstat"></div>
                </div>
                
            </section>
            <section className = "w-full lg:p-5 lg:px-0 flex place-content-center bg-purple-100">
                <div className = "newSectionContainer grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-7 justify-center items-center rounded-lg my-5">
                    <div className = "w-full flex flex-col text-center lg:text-left justify-center lg:items-start items-center">
                        <h2 className = "text-orange text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-7 mb-1 font-bold text-center">Integrations<span className = "text-gray-500"> to make</span></h2>
                        <h2 className = "text-gray-500 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl mb-0 lg:mb-5 font-bold text-center">rewarding super quick.</h2>
                        <p className = "my-8 text-lg lg:text-xl text-gray-500">Seemless integration with your existing HCM/HRIS platforms and single-sign-on (SSO) tools, making it easy for you to onboard.</p>
                        <div className = "grid grid-cols-1 gap-3 justify-center items-center lg:justify-start w-5/6 lg:w-1/2">
                            <button className = "vc-ghost-btn lato font-bold">Explore</button>
                        </div>
                    </div>
                    <div class="right my-10 lg:my-0">
                        <div class="grid grid-cols-3 gap-2 md:gap-4 text-white mb-2 md:mb-4">
                            <div class="bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/SAP.png" width="149" height="75" alt="SAP" />
                            </div>
                            <div class="bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802565/gatsbycms/uploads/2022/02/Yammer.png" width="149" height="37" alt="Yammer" />
                            </div>
                            <div class="bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/MS_Teams.png" width="149" height="34" alt="MS_Teams" />
                            </div>
                        </div>
                        <div class="grid grid-cols-5 gap-2 md:gap-4 text-white">
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802565/gatsbycms/uploads/2022/02/Workplace.png" width="109" height="29" alt="Workplace" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Azure.png" width="93" height="28" alt="Azure" />
                            </div>
                            <div class="row-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Slack.png" width="40" height="152" alt="Slack" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643954900/gatsbycms/uploads/2022/02/Oracle-integration.png" width="99" height="14" alt="Oracle" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Okta.png" width="78" height="28" alt="Okta" />
                            </div>
                            <div class="col-span-2 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/People-strong.png" width="129" height="23" alt="PeopleStrong" />
                            </div>
                            <div class="col-span-3 bg-white rounded shadow-lg p-2 md:p-5 flex justify-center items-center">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/02/Office365.png" width="133" height="30" alt="Office 365" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section class="bottom-cta m-6 md:m-0 relative z-1">
                <div class="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-10 xl:-bottom-32">
                    <div class="bottom-cta-content text-center text-white">
                        <div class="section-title-white">How to choose the best Rewards &amp; Recognition software?</div>
                        <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a class="vc-ghost-btn-cta-banner" href="https://www.vantagecircle.com/resources/rewards-and-recognition-buyers-guide/" target="_blank" rel="noopener">Download Comprehensive Buyer's Guide</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" class="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png" alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" class="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg" alt="VC flower" width="150" height="150"/>
                </div>                         
            </section> */}
        {/* <TwoSlideHome content = {bottomCarousel} showedItems = {2} containerHeight = {23} imgHeight = {2} paraHeight = {10} btnHeight = {3} mobileShowedItems = {1} mobileContainerHeight = {25} mobileImgHeight = {2} mobileParaHeight = {14} mobileBtnHeight = {3} /> */}
        {/* <section className = "w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-0 lg:py-32 2xl:py-40 overflow-y-hidden bg-4">
            <div className = "newSectionContainer grid grid-cols-1 lg:grid-cols-2 gap-5 justify-center items-center z-10"
            <div className = "text-center lg:text-left w-7/12 flex flex-col">
                <h2 className = "">How to choose the <span className = "relative">best<img className = "absolute bottom-0 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /> </span> Rewards &Recognition software?</h2>
                <a href = "" className = "vc-new-orange-btn">Download Comprehensive Buyer's Guide</a>
            </div>
            <div className = "flex justify-center items-center p-3">
                <img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2022/04/Buyers-Guide-book-mocukp.png" alt = "cta" />
            </div>
        </section> */}
        <section className = "w-full flex place-content-center relative overflow-x-hidden py-0 overflow-y-hidden bg-3" style = {{background: "#414171"}}>
            <div className = "w-11/12 lg:px-5 py-0 lg:py-0 my-0 lg:flex justify-center items-center z-10">
                    <div className = "w-full flex flex-col text-center lg:text-left justify-center lg:items-start items-center">
                     
                        <h2 className = "text-purple-100 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-7 mb-1 font-bold lg:text-left text-center">How to choose the <span className = "relative">best<img className = "absolute -bottom-1 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /></span></h2>
                        <h2 className = "text-gray-100 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl lg:mb-6 mb-10 font-bold lg:text-left text-center">Rewards & Recognition software?</h2>
                        {/* <p className = "my-8 text-lg xl:text-xl text-purple-100">Real-time employee feedback, actionable data, increased performance. Pulse lets you do all these and more with our people-first survey tool.</p> */}
                        <div className = "grid grid-cols-1 gap-3 justify-center items-center lg:justify-start w-full md:w-4/6 xl:w-6/12 2xl:w-5/12 cursor-pointer">
                            <a href = "#" className = "vc-new-orange-btn lato">Download Comprehensive Buyer's Guide</a>
                        </div>
                    </div>
                    <div className ="flex justify-center items-center pt-16 lg:pt-0 w-full lg:w-5/12">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Buyers-Guide-book-mocukp.png"  width="751" height="443" />
                    </div>
    
            </div>

            <picture className='absolute top-20 lg:-right-12 xl:-right-10 2xl:-right-3 z-0'>
                    <source media = "(min-width: 1024px)" srcset= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/CTA-banner-stroke.png" />
                    <img src="" alt="" />
            </picture>
         
            {/* <img className = "hidden lg:block absolute top-20 lg:-right-12 xl:-right-10 2xl:-right-3 z-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/CTA-banner-stroke.png" /> */}
        </section>
        {/* <picture>
  <source media = "(max-width: 768px)" srcset="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/05/Homepage-rewards-mobile-1.webp" alt="Vantage Rewards Platform for Best Employee Rewards and Recognition Programs" />
  <source srcset="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/05/Vantage-Circle-Platform-for-employee-rewards-and-recognition-program-03-2.webp" alt="Vantage Rewards Platform for Best Employee Rewards and Recognition Programs" />
  <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2022/04/Vantage-Circle-Platform-for-employee-rewards-and-recognition-program-03.png" alt="Vantage Rewards Platform for Best Employee Rewards and Recognition Programs" width="100%" height="auto" />
</picture> */}
{/* <img class="w-full block md:hidden lg:hidden" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/05/Homepage-rewards-mobile.png" width="330" height="335" alt="Vantage reward platform for best employee rewards and recognition programs" /> */}

    </Layout>
  )
}

export default Newfonthome